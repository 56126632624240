export const roles = {
  sproxil_admin: "SPROXIL_ADMIN",
  client_admin: "CLIENT_ADMIN",
  client_user: "CLIENT_USER",
  shippingRole: "SHIPPING_ROLE",
  commissioningRole: "COMMISSIONING_ROLE",
  decommissioningRole: "DECOMMISSIONING_ROLE",
  encodingRole: "ENCODING_ROLE",
  packingRole: "PACKING_ROLE",
  unpackingRole: "UNPACKING_ROLE",
  receivingRole: "RECEIVING_ROLE",
  dispensingRole: "DISPENSING_ROLE",
  all_roles: "DEFAULT_ROLE",
};
export const clientType = {
  1: "Product Owner",
  2: "Distributor",
  3: "Retailer",
};
