import React, { useState } from "react";

// import { faIndustry } from "@fortawesome/free-solid-svg-icons";
import {
  FaCoins,
  FaCheckCircle,
  FaBox,
  FaBoxOpen,
  FaTruck,
  FaPeopleCarry,
  FaHandHoldingUsd,
} from "react-icons/fa";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  InfoWindowF,
  PolylineF,
} from "@react-google-maps/api";
import { useQuery } from "react-query";
import { format } from "date-fns";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  //   TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
const containerStyle = {
  width: "100%",
  height: "100%",
};
const center = {
  lat: 9.072264,
  lng: 7.491302,
};
function TraceMap({ barcode }) {
  const axiosPrivate = useAxiosPrivate();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API,
  });

  const [indexStore, setIndexStore] = useState([]);

  function handleOpen(index) {
    setIndexStore((prev) => [...prev, index]);
  }
  function handleClose(index) {
    setIndexStore((prev) => prev.filter((idx) => idx !== index));
  }

  const [locationMarker, setLocationMarker] = useState([]);
  const allLocation = async () => {
    const response = await axiosPrivate.get("/locations");
    return response;
  };

  const locations = useQuery("locations", () => {
    return allLocation();
  });

  const getLabelTrace = async (barcode) => {
    const response = await axiosPrivate.get(`/label/${barcode}`);
    return response;
  };

  useQuery(
    "labelTraces",
    () => {
      return getLabelTrace(barcode);
    },
    {
      onSuccess: (data) => {
        const events = data.data.data.trace;
        if (locations.isSuccess) {
          console.log(locations);
          locations.data.data.data.forEach((location) => {
            events.forEach((event) => {
              // const sublat = event.latitude.substring(
              //   0,
              //   event.latitude.indexOf(".") + 3
              // );
              // const sublng = event.longitude.substring(
              //   0,
              //   event.longitude.indexOf(".") + 3
              // );

              if (location.gln === event.readPoint) {
                event.address = location.address;
                event.locationType = location?.locationType;
                event.lat = location.lat;
                event.lng = location.lng;
                // event.lat = parseFloat(location.lat);
                // event.lng = parseFloat(location.lng);
              }
            });
          });
        }
        console.log(events);
        let objArray = [];
        events.forEach((event) => {
          //check if event has taken place already in the register location
          const regEvent = objArray.filter((registerEvent) => {
            // return registerEvent.readPoint === event.readPoint;
            return registerEvent.readPoint === event.readPoint;
          });

          if (regEvent.length === 0) {
            objArray.push({
              address: event.address,
              locationType: event?.locationType,
              readPoint: event.readPoint,
              events: [{ event: event.eventType, event_time: event.eventTime }],
              lat: parseFloat(event.lat),
              lng: parseFloat(event.lng),
            });
          } else {
            objArray.forEach((regEvt) => {
              if (regEvent[0].readPoint === regEvt.readPoint) {
                regEvt.events.push({
                  event: event.eventType,
                  event_time: event.eventTime,
                });
              }
            });
          }
        });
        console.log(objArray);
        setLocationMarker(objArray);
      },
      refetchInterval: false,
    }
  );
  // const lineSymbol = {
  //   path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW
  // };
  return (
    <div className="flex justify-center items-center w-[80vw] h-[80vh] ml-[70px]">
      {isLoaded ? (
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={4}>
          <PolylineF
            path={locationMarker.map((location) => {
              return { lat: location.lat, lng: location.lng };
            })}
            geodesic={true}
            options={{
              strokeColor: "#ff2527",
              strokeOpacity: 0.75,
              strokeWeight: 2,
              icons: [
                {
                  // eslint-disable-next-line no-undef
                  icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
                  offset: "0",
                  repeat: "1000px",
                },
              ],
            }}
          />
          {locationMarker.map((location, index) => {
            return (
              <>
                <MarkerF
                  key={index}
                  label={{
                    text: `${
                      location?.locationType === "Factory"
                        ? "\uebbc"
                        : location?.locationType === "Warehouse"
                        ? "\uebb8"
                        : "\ue543"
                    }`,
                    fontFamily: "Material Icons",
                    color: "#ffffff",
                    fontSize: "18px",
                  }}
                  position={{ lat: location.lat, lng: location.lng }}
                  onClick={() => {
                    handleOpen(index);
                  }}>
                  {indexStore.includes(index) && (
                    <InfoWindowF
                      key={index}
                      position={{ lat: location?.lat, lng: location?.lng }}
                      onCloseClick={() => handleClose(index)}>
                      <div onClick={(e) => e.stopPropagation()}>
                        <Table>
                          <TableCaption>Item Trace</TableCaption>
                          <TableHeader>
                            <TableRow>
                              <TableHead>Events</TableHead>
                              <TableHead>Location</TableHead>
                              <TableHead>Event Date/Time</TableHead>
                            </TableRow>
                          </TableHeader>

                          <TableBody>
                            {location?.events?.map((e, idx) => {
                              return (
                                <TableRow>
                                  <TableCell>
                                    <div
                                      className="flex  gap-[10px] items-center"
                                      key={idx}>
                                      <span>
                                        {e?.event === "encoding" && (
                                          <FaCoins className="text-primary" />
                                        )}
                                        {e?.event === "commissioning" && (
                                          <FaCheckCircle className="text-primary" />
                                        )}
                                        {e?.event === "packing" && (
                                          <FaBox className="text-primary" />
                                        )}
                                        {e?.event === "unpacking" && (
                                          <FaBoxOpen className="text-primary" />
                                        )}
                                        {e?.event === "shipping" && (
                                          <FaTruck className="text-primary" />
                                        )}
                                        {e?.event === "receiving" && (
                                          <FaPeopleCarry className="text-primary" />
                                        )}
                                        {e?.event === "dispensing" && (
                                          <FaHandHoldingUsd className="text-primary" />
                                        )}
                                      </span>
                                      <span>
                                        {" "}
                                        {e?.event === "encoding" && "Encoded"}
                                        {e?.event === "commissioning" &&
                                          "Commissioned"}
                                        {e?.event === "packing" && "Packed"}
                                        {e?.event === "unpacking" && "Unpacked"}
                                        {e?.event === "shipping" && "Shipped"}
                                        {e?.event === "receiving" && "Received"}
                                        {e?.event === "dispensing" &&
                                          "Dispensed"}
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell>{location?.address}</TableCell>
                                  <TableCell>
                                    {format(
                                      new Date(e.event_time),
                                      "yyyy-MM-dd hh:mm:ss"
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </div>
                    </InfoWindowF>
                  )}
                </MarkerF>
              </>
            );
          })}
        </GoogleMap>
      ) : (
        <p>Map loading</p>
      )}
    </div>
  );
}

export default TraceMap;
