import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import Sgtin from "./Sgtin";
import Sscc from "./Sscc";
function Serialization() {
  return (
    <div className="my-[50px]">
      <Tabs defaultValue="sscc" className="w-full overflow-hidden">
        <TabsList>
          <TabsTrigger value="sscc" className=" px-[30px]">
            SSCCs
          </TabsTrigger>
          <TabsTrigger value="sgtin" className=" px-[30px]">
            SGTINs
          </TabsTrigger>
        </TabsList>
        <TabsContent value="sscc">
          <Sscc />
        </TabsContent>
        <TabsContent value="sgtin">
          <Sgtin />
        </TabsContent>
      </Tabs>
    </div>
  );
}

export default Serialization;
