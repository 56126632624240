import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from "../../components/ui/dialog";

import { Button } from "../../components/ui/button";
import { cn } from "../../lib/utils";
import { Calendar } from "../../components/ui/calendar";
import { CalendarIcon, ChevronsUpDown, Check, Eye } from "lucide-react";
import { format, isValid, parseISO } from "date-fns";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../../components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";

import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../../components/ui/pagination";

import { useMutation, useQuery } from "react-query";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "../../components/ui/sheet";
import { useToast } from "../../components/ui/use-toast";
import TraceMap from "../../components/TraceMap";
import { ClipLoader } from "react-spinners";

const labelType = [
  { value: "all", label: "ALL" },
  { value: "0", label: "SGTIN" },
  { value: "1", label: "SSCC" },
];
const pageLimit = 7;
let staticDB;
function ShipTab() {
  const [startdate, setSatrtdate] = useState();
  const [enddate, setEndddate] = useState();
  const [openclient, setOpenClient] = useState(false);
  const [valueClient, setClientValue] = useState("");
  const [open, setOpen] = useState(false);
  const [openDig, setOpenDig] = useState(false);
  //
  //
  const [openProd, setOpenProd] = useState(false);
  const [prodValue, setProdValue] = useState("");

  //
  //
  const [openLabel, setOpenlabel] = useState(false);
  const [labelValue, setLabelalue] = useState("");

  const axiosPrivate = useAxiosPrivate();
  const { allClients } = useAuth();
  const navigate = useNavigate();
  const [eventList, setEventList] = useState([]);
  const [tabArr, setTabArr] = useState([]);
  const [prods, setProds] = useState([]);
  const [parent, setParent] = useState();
  const [barcode, setBarcode] = useState();
  const [downloadData, setDownloadData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  let [pageNumbers, setPageNumbers] = useState([]);

  const getProductsbyKey = async (key) => {
    const response = await axiosPrivate.get(`client/products/${key.clientKey}`);
    return response;
  };
  const getEventData = async (
    page,
    startDate,
    endDate,
    dest,
    source,
    clientKeys,
    prodIds
  ) => {
    const response = await axiosPrivate.get(
      `label/ship/table/?currentPage=${page}&startdate=${startDate}&enddate=${endDate}&source=${source}&destination=${dest}&clientKeys=${clientKeys}&prodIds=${prodIds}`
    );
    return response;
  };

  const downloadEncodedData = async (data) => {
    const response = await axiosPrivate.post("label/commission/download", data);
    return response;
  };

  const handleChange = async (key) => {
    try {
      const KEY = allClients.find(
        (client) => client.key.toLowerCase() === key
      )?.key;

      const prodResponse = await getProductsbyKey({ clientKey: KEY });
      setProds([...prodResponse.data.data]);
    } catch (error) {
      console.log(error);
    }
  };

  const [params, setParams] = useState({
    startDateEncodedLabel: "2023-01-01",
    endDateEncodedLabel: format(new Date(), "yyyy-MM-dd"),
    labelTypeEncodedLabel: "all",
    clientNameEncodedLabel: "all",
    prodNameEncodedLabel: "all",
  });

  const header = [
    { key: "prodName", label: "product Name" },
    { key: "clientName", label: "Client Name" },
    { key: "labelType", label: "Label-Type" },
    { key: "count", label: "Volume" },
    { key: "createdAt", label: "Date" },
  ];

  const { isLoading } = useQuery({
    queryKey: ["item-shipped"],
    queryFn: () =>
      getEventData(
        currentPage,
        startdate || "2023-01-01",
        enddate || format(new Date(), "yyyy-MM-dd hh:mm:ss"),
        "all",
        "all",
        "all"
      ),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      console.log(data);
      setEventList(data?.data?.data?.row);
      staticDB = data?.data?.data?.row;
      setPageNumbers(() => {
        let count = [];
        for (
          let index = 0;
          index < Math.ceil(data?.data?.data?.row.length / pageLimit);
          index++
        ) {
          count.push(index + 1);
        }
        return count;
      });
    },
    onError: (error) => {
      console.log(error);
      if (
        error.response?.status === "403" ||
        error.response?.status === "401"
      ) {
        navigate("/");
      }
      setEventList([]);
    },
  });

  const download = useMutation((variables) => downloadEncodedData(variables), {
    onSuccess(data, variables, context) {
      setDownloadData(
        data?.data?.data.map((event) => {
          return {
            prodName: event.prodName,
            clientName: event.clientName,
            labelType: event.labelType === "0" ? "SGTIN" : "SSCC",
            count: event.count,
            createdAt: format(new Date(event.createdAt), "yyyy-MM-dd hh:mm:ss"),
          };
        })
      );
    },
    onError(error) {},
  });

  // function handleLabelDownload() {
  //   download.mutate(params);
  // }

  // function handleLabelDownload() {}

  function handleParams() {
    // const dateEnd =
    //   enddate !== undefined
    //     ? format(enddate, "yyyy-MM-dd hh:mm:ss")
    //     : format(new Date(), "yyyy-MM-dd hh:mm:ss");
    // const dateStart =
    //   startdate !== undefined
    //     ? format(startdate, "yyyy-MM-dd hh:mm:ss")
    //     : new Date("2022-01-01");
    // const clients = allClients.map((client) => client.key);
    // const prods = auth.user?.userProducts.map((prod) => prod.id);
    // const packs = staticDB.map((pack) => pack.count);
    // const filterArry = filter(staticDB, (x) => {
    //   return (
    //     (valueClient === ""
    //       ? clients.includes(x.memberKey)
    //       : valueClient === x.memberKey.toLowerCase()) &&
    //     (prodValue === ""
    //       ? prods.includes(x.prodId)
    //       : prodValue === x.prodName) &&
    //     new Date(x.createdAt).getTime() >= new Date(dateStart).getTime() &&
    //     new Date(x.createdAt).getTime() <= new Date(dateEnd).getTime()
    //   );
    // });
    // setEventList(filterArry);
    // setPageNumbers(() => {
    //   let count = [];
    //   for (
    //     let index = 0;
    //     index < Math.ceil(filterArry.length / pageLimit);
    //     index++
    //   ) {
    //     count.push(index + 1);
    //   }
    //   return count;
    // });
  }
  useEffect(() => {
    const offSet = pageLimit * currentPage - pageLimit;
    setTabArr(eventList.slice(offSet, pageLimit + offSet));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, eventList]);

  function handlePageChange(dir) {
    if (dir === "next") {
      setCurrentPage((prev) => prev + 1);
    }
    if (dir === "prev") {
      setCurrentPage((prev) => prev - 1);
    }
  }
  function handleDate(date) {
    if (date == null) return "N/A";
    const validDate = parseISO(date, "yyyy-MM-dd", new Date());
    if (isValid(validDate))
      return format(new Date(date), "yyyy-MM-dd hh:mm:ss");
    return "N/A";
  }
  const handleTraceModal = (e, parent) => {
    e.preventDefault();
    setOpen(true);
    console.log(parent);
    setParent(parent);
  };

  return (
    <>
      <Dialog open={openDig} onOpenChange={setOpenDig}>
        <DialogContent>
          <DialogHeader>
            <DialogDescription>
              <TraceMap barcode={barcode} />
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>

      <Sheet open={open} onOpenChange={setOpen}>
        <SheetContent>
          <SheetHeader>
            <SheetTitle>Pack Content</SheetTitle>
            <SheetDescription>
              <span>Parent: </span>
              <Button
                className=" text-[light-green]"
                variant="link"
                onClick={() => {
                  setOpenDig(true);
                  setBarcode(parent);
                }}>
                {parent}
              </Button>
              <hr />
              <br />
            </SheetDescription>
          </SheetHeader>
          <Traces
            parent={parent}
            setOpenDig={setOpenDig}
            setBarcode={setBarcode}
          />
        </SheetContent>
      </Sheet>
      <div className=" overflow-x-hidden">
        <Card className=" overflow-x-hidden">
          <CardHeader>
            <CardTitle className="my-[10px]">Items Commissioned</CardTitle>
            <CardDescription className="flex gap-[10px]">
              <Popover open={openclient} onOpenChange={setOpenClient}>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={openclient}
                    className="w-[200px] justify-between">
                    {valueClient
                      ? allClients.find(
                          (client) =>
                            (client?.key).toLowerCase() === valueClient
                        )?.name
                      : "Pick a client..."}
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-[200px] p-0">
                  <Command>
                    <CommandInput placeholder="Search client..." />
                    <CommandEmpty>No framework found.</CommandEmpty>
                    <CommandGroup>
                      {allClients.map((client, index) => (
                        <CommandItem
                          key={index}
                          value={client?.key}
                          onSelect={(currentValue) => {
                            setClientValue(currentValue);
                            handleChange(currentValue);
                            setOpenClient(false);
                          }}>
                          <Check
                            className={cn(
                              "mr-2 h-4 w-4",
                              valueClient === client?.key
                                ? "opacity-100"
                                : "opacity-0"
                            )}
                          />
                          {client.name}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </Command>
                </PopoverContent>
              </Popover>

              <Popover open={openProd} onOpenChange={setOpenProd}>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={openProd}
                    className="w-[200px] justify-between">
                    {prodValue
                      ? prods.find((prod) => prod.product_name === prodValue)
                          ?.product_name
                      : "Pick a product..."}
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-[200px] p-0">
                  <Command>
                    <CommandInput placeholder="Search product..." />
                    <CommandEmpty>No framework found.</CommandEmpty>
                    <CommandGroup>
                      {prods.map((prod, index) => (
                        <CommandItem
                          key={index}
                          value={prod.id}
                          onSelect={(currentValue) => {
                            console.log(currentValue);
                            setProdValue(currentValue);
                            setOpenProd(false);
                          }}>
                          <Check
                            className={cn(
                              "mr-2 h-4 w-4",
                              prodValue === prod.id
                                ? "opacity-100"
                                : "opacity-0"
                            )}
                          />
                          {prod.product_name}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </Command>
                </PopoverContent>
              </Popover>
              <Popover open={openLabel} onOpenChange={setOpenlabel}>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={openLabel}
                    className="w-[200px] justify-between">
                    {labelValue
                      ? labelType.find(
                          (framework) => framework.value === labelValue
                        )?.label
                      : "Pick label type..."}
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-[200px] p-0">
                  <Command>
                    <CommandInput placeholder="Search label type..." />
                    <CommandEmpty>No label found.</CommandEmpty>
                    <CommandGroup>
                      {labelType.map((framework) => (
                        <CommandItem
                          key={framework.value}
                          value={framework.value}
                          onSelect={(currentValue) => {
                            setLabelalue(
                              currentValue === labelValue ? "" : currentValue
                            );

                            setOpenlabel(false);
                          }}>
                          <Check
                            className={cn(
                              "mr-2 h-4 w-4",
                              labelValue === framework.value
                                ? "opacity-100"
                                : "opacity-0"
                            )}
                          />
                          {framework.label}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </Command>
                </PopoverContent>
              </Popover>

              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant={"outline"}
                    className={cn(
                      "w-[280px] justify-start text-left font-normal",
                      !startdate && "text-muted-foreground"
                    )}>
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {startdate ? (
                      format(startdate, "PPP")
                    ) : (
                      <span>Start date</span>
                    )}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0">
                  <Calendar
                    mode="single"
                    selected={startdate}
                    onSelect={setSatrtdate}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>

              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant={"outline"}
                    className={cn(
                      "w-[280px] justify-start text-left font-normal",
                      !enddate && "text-muted-foreground"
                    )}>
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {enddate ? format(enddate, "PPP") : <span>End date</span>}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0">
                  <Calendar
                    mode="single"
                    selected={enddate}
                    onSelect={setEndddate}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
              <Button onClick={handleParams}>Search</Button>
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-2">
            <Table className=" overflow-x-hidden">
              <TableCaption>A list of all recent shipments.</TableCaption>
              <TableHeader>
                <TableRow>
                  <TableHead>Client Name</TableHead>
                  <TableHead>Product Name</TableHead>
                  <TableHead>Date Shupped</TableHead>
                  <TableHead>Source</TableHead>
                  <TableHead>Destination</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Date Recieved</TableHead>
                  <TableHead>View Details</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {isLoading && (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <div className="flex justify-center">
                        <ClipLoader />
                      </div>
                    </TableCell>
                  </TableRow>
                )}
                {tabArr.map((event) => (
                  <TableRow key={event.id}>
                    <TableCell className="whitespace-nowrap">
                      {event?.clientName}
                    </TableCell>
                    <TableCell className="whitespace-nowrap">
                      {event?.prodName}
                    </TableCell>
                    <TableCell className="whitespace-nowrap">
                      {handleDate(event?.createdAt)}
                    </TableCell>
                    <TableCell className="whitespace-nowrap">
                      {event?.source}
                    </TableCell>
                    <TableCell className="whitespace-nowrap">
                      {event?.destinationName}
                    </TableCell>
                    <TableCell className="whitespace-nowrap">
                      {" "}
                      {event.received === 0 ? "IN-TRANSIST" : "RECEIVED"}
                    </TableCell>

                    <TableCell className="whitespace-nowrap">
                      {handleDate(event?.receivedAt)}
                    </TableCell>
                    <TableCell className="whitespace-nowrap">
                      <Eye
                        className="detail-view"
                        onClick={(e) => {
                          handleTraceModal(e, event?.elementString);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={8}>Total</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4}></TableCell>
                  <TableCell colSpan={4}>
                    <Pagination>
                      <PaginationContent>
                        <PaginationItem>
                          <PaginationPrevious
                            className={`cursor-pointer ${
                              currentPage === 0 && "disable-btn"
                            }`}
                            onClick={() => handlePageChange("prev")}
                          />
                        </PaginationItem>
                        {pageNumbers.map((page, index) => (
                          <PaginationItem
                            key={index}
                            className={"cursor-pointer"}>
                            <PaginationLink
                              onClick={() => setCurrentPage(page)}
                              isActive={currentPage === page}>
                              {page}
                            </PaginationLink>
                          </PaginationItem>
                        ))}
                        <PaginationItem>
                          <PaginationEllipsis />
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationNext
                            className={`cursor-pointer ${
                              currentPage === pageNumbers.length &&
                              "disable-btn"
                            }`}
                            onClick={() => handlePageChange("next")}
                          />
                        </PaginationItem>
                      </PaginationContent>
                    </Pagination>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </CardContent>
          <CardFooter></CardFooter>
        </Card>
      </div>
    </>
  );
}

function Traces({ parent, setOpenDig, setBarcode }) {
  const { toast } = useToast();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const fetchPackContent = async (barcode) => {
    const response = await axiosPrivate.get(`/label/content/${barcode}`);
    return response;
  };
  const { data, isSuccess } = useQuery(
    "packContent",
    () => {
      return fetchPackContent(parent);
    },
    {
      onError: (error) => {
        if (error.code === "ERR_NETWORK") {
          navigate("/", { state: { from: location }, replace: true });
        } else if (error.response.status === 401)
          navigate("/", { state: { from: location }, replace: true });
        else {
          toast({
            variant: "destructive",
            title: "Error",
            description: `${error?.response?.data?.message}`,
          });
        }
      },
    }
  );
  return (
    <ul>
      {isSuccess &&
        data.data.data.length > 0 &&
        data.data.data.map((item, index) => {
          return (
            <li key={index}>
              <Button
                className=" text-[12px]"
                variant="link"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenDig(true);
                  setBarcode(item.elementString);
                  // setTraceBarcode(item.elementString);
                }}>
                {item.elementString}
              </Button>
            </li>
          );
        })}
    </ul>
  );
}
export default ShipTab;
