import { createContext, useState } from "react";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  const [clientModal, setClientModal] = useState(false);
  const [userModal, setUserModal] = useState(false);
  const [ssccModal, setSsccModal] = useState(false);
  const [sgtinModal, setSgtinModal] = useState(false);
  const [allClients, setAllClients] = useState([]);
  const [trace, setTrace] = useState(false);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [closeLogoutForm, setCloseLogoutForm] = useState(false);
  const [closeLogoutFormMobile, setCloseLogoutFormMobile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [closeErrorFalsh, setCloseErrorFalsh] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [editUser, setEditUser] = useState({});
  const [editUserRoleModal, setEditUserRoleModal] = useState(false);
  const [editClientModal, setEditClientModal] = useState(false);
  const [client, setClient] = useState({});
  const [traceModal, setTraceModal] = useState(false);
  const [encodeLabelTable, setEncodeLabelTable] = useState({});
  const [traceParent, setTraceParent] = useState("");
  const [traceBarcode, setTraceBarcode] = useState("");

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        clientModal,
        setClientModal,
        userModal,
        setUserModal,
        ssccModal,
        setSsccModal,
        sgtinModal,
        setSgtinModal,
        allClients,
        setAllClients,
        latitude,
        setLatitude,
        longitude,
        setLongitude,
        closeLogoutForm,
        setCloseLogoutForm,
        closeLogoutFormMobile,
        setCloseLogoutFormMobile,
        loading,
        setLoading,
        closeErrorFalsh,
        setCloseErrorFalsh,
        editUserModal,
        setEditUserModal,
        editUser,
        setEditUser,
        editUserRoleModal,
        setEditUserRoleModal,
        editClientModal,
        setEditClientModal,
        client,
        setClient,
        traceModal,
        setTraceModal,
        encodeLabelTable,
        setEncodeLabelTable,
        trace,
        setTrace,
        traceParent,
        setTraceParent,
        traceBarcode,
        setTraceBarcode,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
