import axios from "axios";
const BASE_URL = process.env.REACT_APP_LOCALHOST_BASE_URL;

export const gs1_api = axios.create({
  baseURL: BASE_URL,
});

export const userLogin = async (data) => {
  const response = await gs1_api.post("/user/login", data, {
    headers: {
      "content-type": "application/json",
    },
    withCredentials: true,
  });
  return response;
};
export const commissionPackage = async (data, token) => {
  const response = await gs1_api.post("/label/commission", data, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const decommissionPackage = async (data, token) => {
  const response = await gs1_api.post("/decommission", data, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const packPackage = async (data, token) => {
  const response = await gs1_api.post("/label/pack ", data, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const unpackPackageContent = async (data, token) => {
  const response = await gs1_api.post("/label/unpack ", data, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const allLocation = async (token) => {
  const response = await gs1_api.get("/locations", {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const shipping = async (data, token) => {
  const response = await gs1_api.post("/label/ship", data, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const receiving = async (data, token) => {
  const response = await gs1_api.post("/label/receive", data, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const getPackContent = async (data, token) => {
  const response = await gs1_api.post(`label/pack/volume/${data}`, data, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const summary = async (token) => {
  const response = await gs1_api.get(
    `summary/?userClients=${JSON.parse(sessionStorage.getItem("userClients"))}`,
    {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};
export const getEventData = async (
  token,
  page,
  startDate,
  endDate,
  dest,
  source,
  clientKeys,
  prodIds
) => {
  const response = await gs1_api.get(
    `label/ship/table/?currentPage=${page}&startdate=${startDate}&enddate=${endDate}&source=${source}&destination=${dest}&clientKeys=${clientKeys}&prodIds=${prodIds}`,
    {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const getEncodedData = async (
  token,
  page,
  startDate,
  endDate,
  labelType,
  clientKeys,
  prodIds
) => {
  const response = await gs1_api.get(
    `/label/commission/table/?currentPage=${page}&startdate=${startDate}&enddate=${endDate}&labeltype=${labelType}&clientKeys=${clientKeys}&prodIds=${prodIds}`,
    {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const downloadEncodedData = async (token, data) => {
  const response = await gs1_api.post("label/commission/download", data, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const downloadPackagePackedData = async (token, data) => {
  const response = await gs1_api.post("/label/pack/download", data, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const getAggregationData = async (token, page, packagePackedParams) => {
  const response = await gs1_api.get(
    `label/pack/table/?currentPage=${page}
  &startdate=${packagePackedParams.startDate}&enddate=${packagePackedParams.endDate}&packSize=${packagePackedParams.packSize}
  &clientKeys=${packagePackedParams.clientName}&prodIds=${packagePackedParams.prodName}`,
    {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const getAllClientsPaginated = async (token, page) => {
  const response = await gs1_api.get(
    `clients/table/?currentPage=${page}&clients=${JSON.parse(
      sessionStorage.getItem("userClients")
    )}`,
    {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response;
};
export const getAllClients = async (token, controller) => {
  JSON.parse(sessionStorage.getItem("userClients"));
  const response = await gs1_api.get(
    `client/?clients=${JSON.parse(sessionStorage.getItem("userClients"))}`,
    {
      signal: controller.sigbal,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const getUserClients = async (token, controller) => {
  JSON.parse(sessionStorage.getItem("userClients"));
  const response = await gs1_api.get(
    `user/clients/?clients=${JSON.parse(
      sessionStorage.getItem("userClients")
    )}`,
    {
      signal: controller.sigbal,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const addNewClient = async (data, token) => {
  const response = await gs1_api.post(`/client`, data, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getlocationbyKey = async (token, key) => {
  const response = await gs1_api.get(`client/location/${key.clientKey}`, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const getProductsbyKey = async (token, key) => {
  const response = await gs1_api.get(`client/products/${key.clientKey}`, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const submitSSCC = async (token, data) => {
  const response = await gs1_api.post(`label/sscc`, data, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const submitSGTIN = async (token, data) => {
  const response = await gs1_api.post(`label/sgtin`, data, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getAllSgtinPaginated = async (token, page) => {
  const response = await gs1_api.get(
    `label/sgtin/table/?currentPage=${page}&clientKeys=all&prodIds=all`,
    {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const getAllSSCCPaginated = async (token, page) => {
  const response = await gs1_api.get(
    `label/sscc/table/?currentPage=${page}&clientKeys=all&prodIds=all`,
    {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const getPackSize = async (token, prodId) => {
  const response = await gs1_api.get(`label/pack/size/${prodId}`, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getAlUsersPaginated = async (token, page) => {
  const response = await gs1_api.get(`users_paginate/?currentPage=${page}`, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getSSCCByTraceId = async (token, traceId) => {
  const response = await gs1_api.get(`label/sscc/${traceId}`, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const getSgtinsByLot = async (token, traceId) => {
  const response = await gs1_api.get(`label/sgtin/${traceId}`, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const addNewUser = async (token, data) => {
  const response = await gs1_api.post(`registration/client_user`, data, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const editRole = async (token, data) => {
  const response = await gs1_api.post(`user/roles`, data, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const addNewAdmin = async (token, data) => {
  const response = await gs1_api.post(`user`, data, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const fetchPartnersByclientId = async (token, data) => {
  const response = await gs1_api.get(`/client/tradepartner/${data}`, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const fetchPackContent = async (token, barcode) => {
  const response = await gs1_api.get(`/label/content/${barcode}`, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const getLabelTrace = async (token, barcode) => {
  const response = await gs1_api.get(`/label/${barcode}`, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const removePartner = async (token, data) => {
  const response = await gs1_api.delete(
    `/client/trade-partner/${data.clientId}/${data.partnerId}`,
    {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};
export const addPartner = async (token, data) => {
  const response = await gs1_api.post(`/client/trade-partner`, data, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

//
export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
});
