import pic from "../pics/landImg.jpeg";

function Footer() {
  return (
    <div className=" relative w-[100%]">
      <div className=" absolute top-[0px]  left-[45%]">
        <div>
          <p className=" text-primary text-[11px] font-extrabold">
            Copyright &copy; {new Date().getFullYear()}
          </p>
        </div>
      </div>
      <div className="h-[10vh] flex items-end w-full justify-center">
        <img src={pic} alt="" className=" md:w-[65%] " />
      </div>
    </div>
  );
}

export default Footer;
