import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Label } from "./ui/label";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../components/ui/command";
import { z } from "zod";
import { useMutation } from "react-query";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "./ui/use-toast";
import { ClipLoader } from "react-spinners";
import useAuth from "../hooks/useAuth";
import { cn } from "../lib/utils";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../components/ui/popover";
import { Check, ChevronsUpDown } from "lucide-react";
import { roles } from "../roles";
function NewUser() {
  const [clientLocations, setClientLocations] = useState([]);
  const axiosPrivate = useAxiosPrivate();

  const navigate = useNavigate();
  const location = useLocation();
  const { allClients } = useAuth();
  const { toast } = useToast();
  const formSchema = z.object({
    email: z.string().email({
      message: "Only valid email is allowed",
    }),
    location: z.string({
      message: "Please check client type",
    }),
    clientKey: z.string({
      message: "Please check client type",
    }),
    firstName: z.string({
      message: "This field is required",
    }),
    lastName: z.string({
      message: "this field is required",
    }),
    password: z
      .string()
      .min(8, {
        message: "this field is required",
      })
      .regex(
        new RegExp(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&=+-]{8,10}$/
        ),
        "Password must be combination of leeter number and special character"
      ),
  });
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
      lastName: "",
      firstName: "",
    },
  });
  function onSubmit(data) {
    data.role = roles.all_roles;
    mutate(data);
  }
  const newUser = async (data) => {
    return await axiosPrivate.post(`user`, data);
  };

  const { mutate, isLoading } = useMutation((variables) => newUser(variables), {
    onSuccess(data, variables, context) {
      toast({
        variant: "success",
        title: "success",
        description: `${"User Successfully Registered"}`,
      });
    },
    onError(error) {
      if (error.code === "ERR_NETWORK") {
        navigate("/", { state: { from: location }, replace: true });
      } else if (
        error.response.status === 401 ||
        error.response.status === 403
      ) {
        navigate("/", { state: { from: location }, replace: true });
      } else {
        toast({
          variant: "destructive",
          title: "Error",
          description: `${error?.response?.data?.message}`,
        });
      }
    },
  });

  const getlocationbyKey = async (key) => {
    const response = await axiosPrivate.get(`client/location/${key.clientKey}`);
    return response;
  };

  const handleChange = async (key) => {
    try {
      const location = await getlocationbyKey({ clientKey: key });
      setClientLocations([...location.data.data]);
    } catch (error) {
      console.log(error);
      if (error.code === "ERR_NETWORK") {
        navigate("/", { state: { from: location }, replace: true });
      } else if (error.response.status === 401)
        navigate("/", { state: { from: location }, replace: true });
      else {
        toast({
          variant: "destructive",
          title: "Error",
          description: `${error?.response?.data?.message}`,
        });
      }
    }
  };

  return (
    <div>
      <div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <Label>First Name</Label>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder={"First name"}
                      autoComplete="off"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <Label>Last Name</Label>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder={"Last name"}
                      autoComplete="off"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <Label>Email Address</Label>
                  <FormControl>
                    <Input
                      type="email"
                      placeholder={"johndoe@mail.com"}
                      autoComplete="off"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <Label>Password</Label>
                  <FormControl>
                    <Input
                      type="password"
                      placeholder={"********************"}
                      autoComplete="off"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="clientKey"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>Clients</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          role="combobox"
                          className={cn(
                            "w-[100%] justify-between",
                            !field.value && "text-muted-foreground"
                          )}>
                          {field.value
                            ? allClients?.find(
                                (client) => client?.key === field.value
                              )?.name
                            : "Select client"}
                          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-[100%] p-0">
                      <Command>
                        <CommandInput placeholder="Search client..." />
                        <CommandEmpty>No client found.</CommandEmpty>
                        <CommandGroup>
                          {allClients.map((client) => (
                            <CommandItem
                              value={client?.key}
                              key={client?.key}
                              onSelect={() => {
                                form.setValue("clientKey", client?.key);
                                handleChange(client?.key);
                              }}>
                              <Check
                                className={cn(
                                  "mr-2 h-4 w-4",
                                  client.key === field.value
                                    ? "opacity-100"
                                    : "opacity-0"
                                )}
                              />
                              {client.name}
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </Command>
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="location"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>Location</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          role="combobox"
                          className={cn(
                            "w-[100%] justify-between",
                            !field.value && "text-muted-foreground"
                          )}>
                          {field.value
                            ? clientLocations?.find(
                                (location) => location?.gln === field.value
                              )?.address
                            : "Select location"}
                          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className=" w-[100%] p-0">
                      <Command>
                        <CommandInput placeholder="Search location.." />
                        <CommandEmpty>No language found.</CommandEmpty>
                        <CommandGroup>
                          {clientLocations.map((location) => (
                            <CommandItem
                              value={location?.gln}
                              key={location.gln}
                              onSelect={() => {
                                form.setValue("location", location?.gln);
                              }}>
                              <Check
                                className={cn(
                                  "mr-2 h-4 w-4",
                                  location?.gln === field.value
                                    ? "opacity-100"
                                    : "opacity-0"
                                )}
                              />
                              {location.address}
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </Command>
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button type="submit">
              {isLoading ? (
                <span className="mx-[20px]">
                  <ClipLoader color="primary" size={20} />
                </span>
              ) : (
                <span>Submit</span>
              )}
            </Button>
          </form>
        </Form>
      </div>
    </div>
  );
}

export default NewUser;
