import React from "react";
import { useForm } from "react-hook-form";
import { Label } from "./ui/label";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { zodResolver } from "@hookform/resolvers/zod";
import { RadioGroup, RadioGroupItem } from "../components/ui/radio-group";

import { z } from "zod";
import { useMutation } from "react-query";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "./ui/use-toast";
import { ClipLoader } from "react-spinners";
function NewClient() {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const { toast } = useToast();

  const addNewClient = async (data) => {
    const response = await axiosPrivate.post(`/client`, data);
    return response;
  };

  const formSchema = z.object({
    email: z.string().email({
      message: "Only valid email is allowed",
    }),
    isNafdacMandate: z.enum(["1", "0"], {
      message: "Please check client type",
    }),
    tradingPartnerType: z.enum(["1", "2", "3"], {
      message: "Please check trade type",
    }),
  });
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });
  function onSubmit(data) {
    console.log(data);
    mutate(data);
  }

  const { mutate, isLoading } = useMutation(
    (variables) => addNewClient(variables),
    {
      onSuccess(data, variables, context) {
        toast({
          variant: "success",
          title: "success",
          description: `${"Client added Successfully"}`,
        });
      },
      onError(error) {
        if (error.code === "ERR_NETWORK") {
          navigate("/", { state: { from: location }, replace: true });
        } else if (
          error.response.status === 401 ||
          error.response.status === 403
        ) {
          navigate("/", { state: { from: location }, replace: true });
        } else {
          toast({
            variant: "destructive",
            title: "Error",
            description: `${error?.response?.data?.message}`,
          });
        }
      },
    }
  );

  return (
    <div>
      <div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <Label>Client Email</Label>
                  <FormControl>
                    <Input
                      type="email"
                      placeholder={"johndoe@mail.com"}
                      name="email"
                      autoComplete="off"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="isNafdacMandate"
              render={({ field }) => (
                <FormItem className="space-y-3">
                  <FormLabel>Client type...</FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className="flex flex-col space-y-1">
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="1" />
                        </FormControl>
                        <FormLabel className="font-normal">
                          NAFDAC MANDATE CLIENT
                        </FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="0" />
                        </FormControl>
                        <FormLabel className="font-normal">
                          NONNAFDAC MANDATE CLIENT
                        </FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="tradingPartnerType"
              render={({ field }) => (
                <FormItem className="space-y-3">
                  <FormLabel>Client Categories...</FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className="flex flex-col space-y-1">
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="1" />
                        </FormControl>
                        <FormLabel className="font-normal">
                          Product Owner
                        </FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="2" />
                        </FormControl>
                        <FormLabel className="font-normal">
                          Distributor
                        </FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="3" />
                        </FormControl>
                        <FormLabel className="font-normal">Retailer</FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button type="submit">
              {isLoading ? (
                <span className="mx-[20px]">
                  <ClipLoader color="primary" size={20} />
                </span>
              ) : (
                <span>Submit</span>
              )}
            </Button>
          </form>
        </Form>
      </div>
    </div>
  );
}

export default NewClient;
