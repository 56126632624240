import useAuth from "./hooks/useAuth";

export const useVerifyUserRole = () => {
  const { auth } = useAuth();

  let userRoles = auth.user.role.split(",");
  function verifyRole(...userRole) {
    const verifyRoles = [...userRole, ...userRoles];
    // eslint-disable-next-line array-callback-return
    const truthTable = verifyRoles.map((role) => {
      if (verifyRoles.indexOf(role) !== verifyRoles.lastIndexOf(role)) {
        return true;
      }
    });
    if (!truthTable.includes(true)) return false;
    else return true;
  }
  return verifyRole;
};
