import axios from "../utils/apis/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    const response = await axios.get("/refresh", {
      withCredentials: true,
    });
    setAuth({
      accessToken: response.data.data.accessToken,
      user: response.data.data.user,
      providerToken: response.data.data.providerToken,
    });
    return response?.data?.data?.accessToken;
  };
  return refresh;
};

export default useRefreshToken;
