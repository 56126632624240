import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { Button } from "../components/ui/button";
import { format, isValid, parseISO } from "date-fns";

import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../components/ui/pagination";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../components/ui/sheet";

import { useQuery } from "react-query";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "../components/ui/input";
import { useToast } from "../components/ui/use-toast";
import EditUser from "../components/EditUser";
import NewUser from "../components/NewUser";
import useAuth from "../hooks/useAuth";
import { ClipLoader } from "react-spinners";

const pageLimit = 7;
let staticDB;
function User() {
  const [open, setOpen] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [eventList, setEventList] = useState([]);
  const [tabArr, setTabArr] = useState([]);
  const location = useLocation();
  const { toast } = useToast();
  const { allClients } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  let [pageNumbers, setPageNumbers] = useState([]);
  const [user, setUser] = useState({});
  const getAlUsersPaginated = async () => {
    const response = await axiosPrivate.get(`users_paginate/?currentPage=${1}`);
    return response;
  };
  const { isLoading } = useQuery({
    queryKey: ["Allusers"],
    queryFn: () => getAlUsersPaginated(),
    onError: (error) => {
      if (error.code === "ERR_NETWORK") {
        navigate("/", { state: { from: location }, replace: true });
      } else if (
        error.response.status === 401 ||
        error.response.status === 403
      ) {
        navigate("/", { state: { from: location }, replace: true });
      } else {
        toast({
          variant: "destructive",
          title: "Error",
          description: `${error?.response?.data?.message}`,
        });
      }
    },
    onSuccess: (data) => {
      setEventList(data?.data?.data?.row);
      staticDB = data?.data?.data?.row;
      setPageNumbers(() => {
        let count = [];
        for (
          let index = 0;
          index < Math.ceil(data?.data?.data?.row.length / pageLimit);
          index++
        ) {
          count.push(index + 1);
        }
        return count;
      });
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  //   const header = [
  //     { key: "prodName", label: "product Name" },
  //     { key: "clientName", label: "Client Name" },
  //     { key: "labelType", label: "Label-Type" },
  //     { key: "count", label: "Volume" },
  //     { key: "createdAt", label: "Date" },
  //   ];

  //   function handleParams() {
  //     const dateEnd =
  //       enddate !== undefined
  //         ? format(enddate, "yyyy-MM-dd hh:mm:ss")
  //         : format(new Date(), "yyyy-MM-dd hh:mm:ss");
  //     const dateStart =
  //       startdate !== undefined
  //         ? format(startdate, "yyyy-MM-dd hh:mm:ss")
  //         : new Date("2022-01-01");
  //     const clients = allClients.map((client) => client.key);
  //     const prods = auth.user?.userProducts.map((prod) => prod.id);
  //     const labels = labelType.map((label) => label.value);
  //     const filterArry = filter(staticDB, (x) => {
  //       return (
  //         (valueClient === ""
  //           ? clients.includes(x.clientKey)
  //           : valueClient === x.clientKey.toLowerCase()) &&
  //         (prodValue === ""
  //           ? prods.includes(x.prodId)
  //           : prodValue === x.prodName) &&
  //         (labelValue === "" || labelValue === "all"
  //           ? labels.includes(x.labelType)
  //           : parseInt(labelValue) === x.labelType) &&
  //         new Date(x.createdAt).getTime() >= new Date(dateStart).getTime() &&
  //         new Date(x.createdAt).getTime() <= new Date(dateEnd).getTime()
  //       );
  //     });
  //     setPageNumbers(() => {
  //       let count = [];
  //       for (
  //         let index = 0;
  //         index < Math.ceil(filterArry.length / pageLimit);
  //         index++
  //       ) {
  //         count.push(index + 1);
  //       }
  //       return count;
  //     });
  //     console.log(filterArry);
  //     setEventList(filterArry);
  //   }

  useEffect(() => {
    const offSet = pageLimit * currentPage - pageLimit;
    setTabArr(eventList.slice(offSet, pageLimit + offSet));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, eventList]);

  function handlePageChange(dir) {
    if (dir === "next") {
      setCurrentPage((prev) => prev + 1);
    }
    if (dir === "prev") {
      setCurrentPage((prev) => prev - 1);
    }
  }

  function handleDate(date) {
    if (date == null) return "N/A";
    const validDate = parseISO(date, "yyyy-MM-dd", new Date());
    if (isValid(validDate))
      return format(new Date(date), "yyyy-MM-dd hh:mm:ss");
    return "N/A";
  }

  const handleEdit = (e, user) => {
    e.preventDefault();
    setUser(user);
    setOpen(true);
  };

  return (
    <>
      <Sheet open={open} onOpenChange={setOpen}>
        <SheetContent className=" min-w-[650px]">
          <SheetHeader>
            <SheetTitle>Edit User Profile</SheetTitle>
            <SheetDescription>
              <EditUser user={user} />
            </SheetDescription>
          </SheetHeader>
        </SheetContent>
      </Sheet>
      <div className=" overflow-hidden">
        <Card>
          <CardHeader>
            <CardTitle className="my-[10px]">List of all users</CardTitle>
            <CardDescription className="flex gap-[10px] w-[60vw]">
              <Input className="w-[80%]" />
              <Sheet>
                <SheetTrigger asChild>
                  <Button variant="outline">New User</Button>
                </SheetTrigger>
                <SheetContent className=" min-w-[450px]">
                  <SheetHeader>
                    <SheetTitle>New User Registration</SheetTitle>
                    <SheetDescription>
                      Enter the required user profile here. Click submit when
                      you're done.
                    </SheetDescription>
                  </SheetHeader>
                  <NewUser />
                </SheetContent>
              </Sheet>
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-2">
            <Table className="">
              <TableCaption>A list of All Available Users</TableCaption>
              <TableHeader>
                <TableRow>
                  <TableHead>Client</TableHead>
                  <TableHead className=" ">Name(s)</TableHead>
                  <TableHead>Email</TableHead>
                  <TableHead>Roles</TableHead>
                  <TableHead>Date</TableHead>
                  <TableHead className="text-right">Action</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {isLoading && (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <div className="flex justify-center">
                        <ClipLoader />
                      </div>
                    </TableCell>
                  </TableRow>
                )}
                {tabArr.map((event) => (
                  <TableRow key={event.id}>
                    <TableCell className="font-medium whitespace-nowrap ">
                      {
                        allClients.find(
                          (client) => client.key === event?.client_key
                        )?.name
                      }
                    </TableCell>
                    <TableCell className="font-medium whitespace-nowrap">
                      {event?.firstName} {event?.lastName}
                    </TableCell>
                    <TableCell>{event?.email}</TableCell>
                    <TableCell className=" whitespace-break-spaces">
                      {event?.role}
                    </TableCell>
                    <TableCell className="whitespace-nowrap">
                      {handleDate(event?.createdAt)}
                    </TableCell>
                    <TableCell className="text-right">
                      <Button
                        variant="link"
                        onClick={(e) => handleEdit(e, event)}>
                        Edit-user
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={6}>Total</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={6}>
                    <Pagination>
                      <PaginationContent>
                        <PaginationItem>
                          <PaginationPrevious
                            className={`cursor-pointer ${
                              currentPage === 0 && "disable-btn"
                            }`}
                            onClick={() => handlePageChange("prev")}
                          />
                        </PaginationItem>
                        {pageNumbers.map((page, index) => (
                          <PaginationItem
                            key={index}
                            className={"cursor-pointer"}>
                            <PaginationLink
                              onClick={() => setCurrentPage(page)}
                              isActive={currentPage === page}>
                              {page}
                            </PaginationLink>
                          </PaginationItem>
                        ))}
                        <PaginationItem>
                          <PaginationEllipsis />
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationNext
                            className={`cursor-pointer ${
                              currentPage === pageNumbers.length &&
                              "disable-btn"
                            }`}
                            onClick={() => handlePageChange("next")}
                          />
                        </PaginationItem>
                      </PaginationContent>
                    </Pagination>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </CardContent>
          <CardFooter></CardFooter>
        </Card>
      </div>
    </>
  );
}

export default User;
